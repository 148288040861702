:root {
  --color-gray-hex: #182026;
  --color-green-hex: #00e180;
  --color-red-hex: #ff1c36;
  --color-blue-hex: #3c86fd;

  --color-red-hsl-val: 353 100% 55%;
  --color-red-hs-val: 353 100%;
  --color-red-hsl-light: hsla(var(--color-red-hsl-val) / 0.2);

  --color-green-hsl-val: 154 100% 44%;
  --color-green-hs-val: 154 100%;
  --color-green-hsl-light: hsla(var(--color-red-hsl-val) / 0.2);

  --color-gray-hsl-val: 216 23% 12%;
  --color-gray-hs-val: 216 23%;
}
