:root {
  --sst-titlebar-height: 50px;
  --sst-timeline-height: 185px;
}

body {
  margin: 0;
  letter-spacing: 0.33px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: rgb(235 241 245 / 25%);
}

input:focus {
  outline: none;
}

#sst .bp3-overlay-content {
  margin: 0 5px;
}

#sst .bp3-popover2.bp3-tooltip2 {
  max-width: 490px;
}
#sst .bp3-tooltip2 .bp3-popover2-content {
  background: white;
}
#sst .bp3-tooltip2 .bp3-popover2-content,
.bp3-tooltip2 .bp3-heading {
  color: black;
}

.container-message {
  min-width: 100vw;
  min-height: 100vh;
  justify-content: center;

  display: flex;
  align-items: center;
}
